import React from 'react';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import Image from 'next/image';

import Checkers from '../../../../helpers/class/Checkers';
import ViewPort from '../../../ViewPort';
import Link from "next/link";

export default function ColazType ( props ) {
  const builder = props.builder;
  const content = props.content;
  const articles = props.articles;

  let container_class = 'container-fluid';
  if ( props.type == 'blog' ) {
    if ( Checkers.isValidString( builder.builder_news_view_width ) ) {
      container_class = builder.builder_news_view_width;
    }
  } else if ( props.type == 'category' ) {
    if ( Checkers.isValidString( content.category_view_width ) ) {
      container_class = content.category_view_width;
    }
  }

  let counter = 1;
  let skeletonArticles = [];
  for ( let i=0; i<6; i++ ) {
    let col_class;
    let currentRow = Math.floor( i / 3 );
    if ( ( currentRow ) % 2 == 0 ) {
      if ( counter === 1 ) {
        col_class = 'col-12 col-sm-12 col-md-12 col-lg-6';
      } else {
        col_class = 'col-6 col-sm-6 col-md-6 col-lg-3';
      }
      if ( counter === 3 ) {
        counter = 1;
      } else {
        counter++;
      }
    } else {
      if ( counter === 3 ) {
        col_class = 'col-12 col-sm-12 col-md-12 col-lg-6';
      } else {
        col_class = 'col-6 col-sm-6 col-md-6 col-lg-3';
      }
      if ( counter === 3 ) {
        counter = 1;
      } else {
        counter++;
      }
    }
    skeletonArticles.push(
      <div key={i.toString()} className={`${col_class} p-0`}>
        <Skeleton height={400} className="col-12 b-rad-md"/>
      </div>
    );
  }

  counter = 1;
  return (
    <div className={`${container_class} ` + (container_class=='container-fluid' ? 'p-0' : '')}>
      <div className="row w-100 m-0">
        {props.loadingArticles ?
          skeletonArticles
          : articles!=undefined && articles.length>0 && articles.map( ( article, key ) => {
            let col_class = 'col-12 col-sm-12 col-md-12 col-lg-6';
            let ar_class = 'ar-2-1';

            let currentRow = Math.floor( key / 3 );
            if ( articles.length > 6 ) {
              if ( ( currentRow ) % 2 == 0 ) {
                if ( counter === 1 ) {
                  col_class = 'col-12 col-sm-12 col-md-12 col-lg-6';
                  ar_class = 'ar-2-1';
                } else {
                  col_class = 'col-6 col-sm-6 col-md-6 col-lg-3';
                  ar_class = 'ar-1-1';
                }
                if ( counter === 3 ) {
                  counter = 1;
                } else {
                  counter++;
                }
              } else {
                if ( counter === 3 ) {
                  col_class = 'col-12 col-sm-12 col-md-12 col-lg-6';
                  ar_class = 'ar-2-1';
                } else {
                  col_class = 'col-6 col-sm-6 col-md-6 col-lg-3';
                  ar_class = 'ar-1-1';
                }
                if ( counter === 3 ) {
                  counter = 1;
                } else {
                  counter++;
                }
              }
            } else {
              switch ( articles.length ) {
              case 1:
                break;
              case 2:
              case 3:
                if ( key > 0 ) {
                  col_class = 'col-6 col-sm-6 col-md-6 col-lg-3';
                  ar_class = 'ar-1-1';
                }
                break;
              case 4:
                col_class = 'col-12 col-sm-12 col-md-12 col-lg-6';
                ar_class = 'ar-2-1';
                break;
              case 5:
                if ( key > 2 ) {
                  col_class = 'col-12 col-sm-12 col-md-12 col-lg-6';
                  ar_class = 'ar-2-1';
                } else if ( key > 0 ) {
                  col_class = 'col-6 col-sm-6 col-md-6 col-lg-3';
                  ar_class = 'ar-1-1';
                }
                break;
              case 6:
                if ( key > 4 ) {
                  col_class = 'col-12 col-sm-12 col-md-12 col-lg-6';
                  ar_class = 'ar-2-1';
                } else if ( key > 2 ) {
                  col_class = 'col-6 col-sm-6 col-md-6 col-lg-3';
                  ar_class = 'ar-1-1';
                } else if ( key > 0 ) {
                  col_class = 'col-6 col-sm-6 col-md-6 col-lg-3';
                  ar_class = 'ar-1-1';
                }
                break;
              default:
                col_class = 'col-6 col-sm-6 col-md-6 col-lg-3';
                ar_class = 'ar-1-1';
                break;
              }
            }
            col_class += '\ no-padding hover-zoom';
            col_class += article.show_in_different_page || Checkers.isValidString( article.description ) ? '\ cursor-pointer' : '';

            ar_class += '\ bg-master-dark';
            const created =
                              moment( new Date( ( article.created ) * 1000 ) ).format( 'dddd Do MMMM' )
                          ;
            const title = props.articlesContentType == 'blog' ? created : article.title;
            const subtitle = props.articlesContentType == 'article' ? article.subtitle :props.articlesContentType == 'blog' ? article.title : article.description;

            return (
              <ViewPort
                parentClassName={col_class}
                key={article.id.toString()}
                skeletonItem={
                  <Skeleton height={200} className="col-12 b-rad-md"/>
                }
              >
                <Link href={props.updateHref( article )}>
                  <a key={article.id.toString()}
                     onClick={() => props.clickHandler( article )}
                    className={props.setClass( article, col_class )}
                  >
                    <div className="inner bottom-left text-left padding-30">
                      <h5 className="block-title text-white m-0">{title}</h5>
                      <h6 className="bold text-white line-clamp-2 m-t-5 m-b-0 hidden-xs">{subtitle}</h6>
                      {
                        article.has_description == '1' ?
                            <>
                              <p className="font-arial fs-11 text-white muted m-t-5 mb-0 hidden-xs">Read more</p>
                            </>
                            :
                            <>
                              <p className="font-arial fs-11 text-white muted m-t-5 mb-0 hidden-xs">&nbsp;</p>
                            </>
                      }
                    </div>
                    <div className={ar_class + '\ brightness-filter-80'}>
                      <div className="psuedo-background" data-pages-bg-image>
                        {
                          Checkers.isValidString( article.image ) &&
                            <Image
                              className="psuedo-background-img"
                              layout={'fill'}
                              sizes={'75vw'}
                              quality={'75'}
                              src={article.image}
                              alt={'Post Img'}
                              objectFit={'cover'}
                              placeholder={'blur'}
                              blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                            />
                        }
                      </div>
                    </div>
                  </a>
                </Link>
              </ViewPort>
            )
          })
        }
      </div>
    </div>
  )
}
